import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import Search from '../components/Search';
import { Container } from '../styles';
import { PageHeader } from '../components/Commons';

const SearchPage = () => (
	<Layout>
		<SEO title="Search" />
        <PageHeader title="Buscar" subtitle="Lorem ipsum lorem lorem lorem lorem lorem" />
        <Container>
            <Search />
        </Container>
	</Layout>
)

export default SearchPage

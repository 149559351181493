import styled from 'styled-components';
import media from 'styled-media-query';

export const SearchWrapper = styled.section`
	display: flex;
	flex-direction: column;
	width: 100%;
	transition: opacity 0.4s;

	.ais-InstantSearch__root {
		display: flex;
		flex-direction: column;
		height: auto;
		width: 100%;
	}
	.ais-SearchBox{
		padding: 1rem 0;

		${media.lessThan('large')`
			padding: 0.5rem 1rem;
		`}
	}
	.ais-SearchBox {

		${media.lessThan('large')`
			padding: 1rem;
		`}
	}
	.ais-Stats {
		color: var(--background-font);
        padding: 0 1.5rem 2rem;
	}

	body#grid & {
		.ais-Hits-list {
			background-color: var(--background-auxiliar);
			border: 1px solid var(--background-auxiliar);
			display: grid;
			grid-area: card;
			grid-gap: 1px;
			grid-template-areas: repeat(auto-fit, minmax(380px, 1fr));
			margin-top: 2rem;
		}

		.ais-Hits-item {
		}
	}

	.ais-SearchBox-input {
		background: none;
		border: 1px solid var(--background-auxiliar);
		border-radius: 5px;
		color: var(--background-font);
		display: flex;
		font-size: 1.6rem;
		padding: 0.5rem;
		width: 100%;
		&::placeholder {
			color: var(--background-font);
		}
	}
	.ais-SearchBox-submit,
	.ais-SearchBox-reset {
		display: none;
	}
`
